import React, { Fragment, useState } from "react";
import axios from 'axios';
import moment from "moment";

const Booking = () => {


    // const api = axios.create({
    //     baseURL: "https://www.triveego.com/",
    //     headers: {
    //         "Content-Type": "application/json",
    //     }
    // })

    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropLocation, setDropLocation] = useState('');
    const [pickerDate, setPickerDate] = useState('');
    const [cabType, setCabType] = useState('Choose Cab');

    const [nameError, setNameError] = useState('');
    const [contactError, setContactError] = useState('');
    const [pickupLocationError, setPickupLocationError] = useState('');
    const [dropLocationError, setDropLocationError] = useState('');
    const [pickerDateError, setPickerDateError] = useState('');
    const [cabTypeError, setCabTypeError] = useState('');
    const [successPopupShow, setSuccessPopupShow] = useState('');

    // const formErrors = { name: '', contact: '', pickupLocation: '', dropLocation: '', pickerDate: '', cabType: '' };
    const closeModalPopup = () => {
        setSuccessPopupShow(false);
    }
    const submitBooking = async () => {
        const mobileNoRegex = RegExp(
            /^((\+){0,1}91(\s){0,1}(\-){0,1}(\s){0,1}){0,1}[6-9][0-9](\s){0,1}(\-){0,1}(\s){0,1}[1-9]{1}[0-9]{7}$/gm
        );
        name.length < 1 ? setNameError("Please enter the name.") : setNameError("");
        contact.length < 1 ? setContactError("Please enter the contact no.") : mobileNoRegex.test(contact) ? setContactError("") : setContactError("Please enter valid mobile no.");
        pickupLocation.length < 1 ? setPickupLocationError("Please enter the pick up location.") : setPickupLocationError("");
        dropLocation.length < 1 ? setDropLocationError("Please enter the drop location.") : setDropLocationError("");
        pickerDate.length < 1 ? setPickerDateError("Please select the pick up date.") : setPickerDateError("");

        cabType === 'Choose Cab' ? setCabTypeError("Please select the pick up date.") : setCabTypeError("");
        if (name.length > 0 && contact.length > 0 && pickupLocation.length > 0 && dropLocation.length > 0 && pickerDate.length > 0 && cabType.length > 0 && cabType !== 'Choose Cab') {

            console.log(mobileNoRegex.test(contact));
            if (mobileNoRegex.test(contact)) {
                console.log('coming here 2');
                let payload = {
                    name: name,
                    contact: contact,
                    pickupLocation: pickupLocation,
                    dropLocation: dropLocation,
                    pickerDate: pickerDate,
                    cabType: cabType,
                    currentDateTime: moment().format("YYYY-MM-DD HH:mm:ss")
                }
                try {
                    const { data } = await axios.post(`https://triveego.com/api/booking.php`, { 'data': payload },
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        });
                    // const { data } = await api.post(`save-booking`, { 'data': payload });
                    if (data) {
                        //$('#bookingQueryModalPopup').modal('show');
                        setSuccessPopupShow(true);
                        setName('');
                        setContact('');
                        setPickupLocation('');
                        setDropLocation('');
                        setPickerDate('');
                        setCabType('Choose Cab');
                    }
                } catch (err) {
                    console.error(err.message);
                }
            }
        }
    }


    return (
        <Fragment>

            <div className="booking-area">
                <div className="container">
                    <div className="booking-form">
                        <h4 className="booking-title">Book Your Ride</h4>
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />

                                        {nameError?.length > 0 && (
                                            <span className="errorMessage">{nameError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Contact</label>
                                        <input type="text" className="form-control" placeholder="Number" value={contact} onChange={e => setContact(e.target.value)} />

                                        {contactError?.length > 0 && (
                                            <span className="errorMessage">{contactError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Pick Up Location</label>
                                        <input type="text" className="form-control" placeholder="Type Location" value={pickupLocation} onChange={e => setPickupLocation(e.target.value)} />

                                        {pickupLocationError?.length > 0 && (
                                            <span className="errorMessage">{pickupLocationError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Drop Off Location</label>
                                        <input type="text" className="form-control" placeholder="Type Location" value={dropLocation} onChange={e => setDropLocation(e.target.value)} />

                                        {dropLocationError?.length > 0 && (
                                            <span className="errorMessage">{dropLocationError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Cab Type</label>
                                        <div className="dropdown">
                                            <div className="nice-select select" data-bs-toggle="dropdown" tabIndex={0}>
                                                <span className="current">{cabType}</span>
                                            </div>
                                            <ul className="list dropdown-menu">
                                                <li className="option" onClick={e => setCabType('Dzire')}>Dzire</li>
                                                <li className="option" onClick={e => setCabType('Ertiga')}>Ertiga</li>
                                                <li className="option" onClick={e => setCabType('Fronx')}>Fronx</li>
                                            </ul>
                                        </div>
                                        {cabTypeError?.length > 0 && (
                                            <span className="errorMessage">{cabTypeError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>Pick Up Date</label>
                                        <input type="date" className="form-control date-picker hasDatepicker" placeholder="DD/MM/YYYY" id="dp1695447298846" value={pickerDate} onChange={e => setPickerDate(e.target.value)} />
                                        {pickerDateError?.length > 0 && (
                                            <span className="errorMessage">{pickerDateError}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-2 align-self-end">
                                    <button onClick={submitBooking} className="theme-btn" type="button">Book Taxi</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {(successPopupShow) ?
                <div id="bookingQueryModalPopup" className={"modal booking_modal_popup " + (successPopupShow ? 'show' : '')} style={{ display: "block" }} data-bs-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={closeModalPopup} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body"><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} viewBox="0 0 24 24" fill="none" stroke="#a7e92f" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" /><polyline points="22 4 12 14.01 9 11.01" /></svg>
                                <h3>Sent Successfully !</h3>
                                <p>Thank you for your booking request, we will call you shortly.</p></div></div>

                        </div>
                    </div>
                </div>

                :
                ''

            }

        </Fragment>
    );
}

export default Booking;