import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import moment from "moment";

const Admin = () => {
    const api = axios.create({
        baseURL: "https://www.triveego.com/",
        headers: {
            "Content-Type": "application/json",
        }
    })

    const [bookingList, setBookingList] = useState([]);

    const getPosts = async () => {
        try {

            try {
                const { data } = await api.get(`get-booking-details`);
                const jsonData = await data;
                setBookingList(jsonData)
            } catch (err) {
                console.error(err.message);
            }
        } catch (err) {
            console.log(err.message);
        }

    }

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <Fragment>

            CUSTOMER DATA TABLE

            <div class="customer_table_ctrl">
                <div class="customer_table_inner">
                    <div class="table_header">Customer</div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Customer Id</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Pick Up Location</th>
                                <th>Drop Off Location</th>
                                <th>Cab Type</th>
                                <th>Pick Up Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bookingList.map(todo => {
                                    let pickupDate = todo.pickup_date;
                                    return (
                                        <tr key={todo.id}>
                                            <td>{todo.id}</td>
                                            <td>{todo.name}</td>
                                            <td>{todo.contact_no}</td>
                                            <td>{todo.pickup_location}</td>
                                            <td>{todo.drop_location}</td>
                                            <td>{todo.cab_type}</td>
                                            <td>{moment(pickupDate).format('ll')}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>
    );
}

export default Admin;