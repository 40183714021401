import slider from '../images/bg_hero2.jpeg';
const Slider = () => {

    return (

        <div className="hero-section">
            <div className="hero-slider owl-carousel owl-theme owl-loaded ">
                <div className="owl-stage-outer">
                    <div className="owl-stage">
                        <div className="owl-item">
                            <div className="hero-single" style={{ backgroundImage: `url(${slider})` }}>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 col-lg-10 mx-auto">
                                            <div className="hero-content text-center">
                                                {/* <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                                    Taxica!
                                                </h6> */}
                                                <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                                    Book <span>Taxi</span> For Your Ride
                                                </h1>
                                                {/* <p>Experience hassle-free travel with our prompt and professional taxi service. Whether it's a quick <br />ride to the airport or a city tour, we've got you covered.</p> */}
                                                {/* <div className="hero-btn justify-content-center">
                                                    <a href="#" className="theme-btn">About More<i className="fas fa-arrow-right" /></a>
                                                    <a href="#" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Slider;