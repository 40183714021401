import breadcrumbImg from '../images/breadcrumb.jpg';

const BreadcrumbComponent = ({ name, title }) => {
    return (
        <div class="site-breadcrumb" style={{ backgroundImage: `url(${breadcrumbImg})` }}>
            <div class="container">
                <h2 class="breadcrumb-title">{title}</h2>
                <ul class="breadcrumb-menu">
                    <li><a href="index.html">Home</a></li>
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                    <li class="active">{name}</li>
                </ul>
            </div>
        </div>
    );
}
export default BreadcrumbComponent;