import Slider from "../components/slider";
import Booking from "../components/booking";
import AboutComponent from "../components/about";
import ServicesComponent from "../components/services";
import TaxiComponent from "../components/taxi";
// import FeatureComponent from "../components/feature";
// import WhyChooseUsComponent from "../components/whyChooseUs";
// import TestimonialComponent from "../components/testimonial";
import CtaComponent from "../components/cta";


const Index = () => {



    /*const checkRequest = async () => {
        try {
            const res = await fetch("http://localhost:5000/test", {
                method: "GET"
            });

        } catch (err) {
            console.error(err.message);
        }
    };*/


    return (
        <main className="main">
            <Slider />
            <Booking />
            {/* <WhyChooseUsComponent /> */}
            <AboutComponent />
            <ServicesComponent />
            <TaxiComponent />
            {/* <CounterComponent /> */}
            {/* <FeatureComponent /> */}
            {/* <TestimonialComponent /> */}
            <CtaComponent />
            <div class="floating_btn contact_us_by_number">
                <a href="tel:+917906636662">
                    <div class="contact_icon">
                        <i class="fa fa-phone my-float"></i>
                    </div>
                </a>
            </div>
            <div class="floating_btn">
                <a target="_blank" href="https://whatsapp.com/dl/">
                    <div class="contact_icon">
                        <i class="fa fa-whatsapp my-float"></i>
                    </div>
                </a>
            </div>
        </main>
    );
}

export default Index;