import BreadcrumbComponent from "../components/breadcrumb";
import ServicesComponent from "../components/services/services";


const ServicePageComponent = () => {
    return (
        <main className="main">
            <BreadcrumbComponent name="Services" title="Services" />
            <ServicesComponent />
        </main>
    );
}

export default ServicePageComponent;