import { NavLink } from 'react-router-dom';
const Footer = () => {

    return (
        <footer className="footer-area">
            <div className="footer-widget">
                <div className="container">
                    <div className="row footer-widget-wrapper pt-120 pb-70">
                        <div className="col-md-6 col-lg-6">
                            <div className="footer-widget-box about-us">
                                <a href="#" className="footer-logo">
                                    <img src="assets/img/logo/logo-light.png" alt="" />
                                </a>
                                <p className="mb-3">
                                    Ensuring that our clients fulfill their hopes and aspirations while traveling and have a wonderful experience. We want customers to have lifelong memories of their vacation with us.
                                </p>
                                <ul className="footer-contact">
                                    <li><a href="tel:+917906636662"><svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>+91 7906636662</a></li>
                                    <li><a><svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx={12} cy={10} r={3} /></svg>11km Stone, Chamba Mussoorie Road, Tehri, Kanatal, Uttarakhand</a></li>
                                    <li><a href="mailto:info@triveego.com"><svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1={22} y1={2} x2={11} y2={13} /><polygon points="22 2 15 22 11 13 2 9 22 2" /></svg>info@triveego.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget-box list">
                                <h4 className="footer-widget-title">Quick Links</h4>
                                <ul className="footer-list">
                                    <li className="nav-item"><NavLink to="/" className="nav-link">Home</NavLink></li>
                                    <li className="nav-item"><NavLink to="about" className="nav-link">About</NavLink></li>
                                    <li className="nav-item"><NavLink to="services" className="nav-link">Services</NavLink></li>
                                    <li className="nav-item"><NavLink to="contact" className="nav-link">Contact</NavLink></li>
                                </ul>

                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget-box list">
                                <h4 className="footer-widget-title">Newsletter</h4>
                                <div className="footer-newsletter">
                                    <p>Subscribe Our Newsletter To Get Latest Update And News</p>
                                    <div className="subscribe-form">
                                        <form action="#">
                                            <input type="email" className="form-control" placeholder="Your Email" />
                                            <button className="theme-btn" type="submit">
                                                Subscribe Now
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;