import { Link } from 'react-router-dom';
import img01 from '../images/Chardham.jpg';
import img02 from '../images/city-tour.jpeg';
import img03 from '../images/airport.jpeg';
import img04 from '../images/04.jpg';
import img05 from '../images/05.jpg';
import img06 from '../images/06.jpg';
const ServicesComponent = () => {
    return (
        <div class="service-area py-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mx-auto">
                        <div class="site-heading text-center">
                            <span class="site-title-tagline">Services</span>
                            <h2 class="site-title">Our Best Services For You</h2>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img02} alt="City-Taxi" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    City Taxi
                                </h3>
                                <p class="service-text">
                                    Explore the beauty and attraction of your city with with triveego taxi service.
                                </p>
                                <div class="service-arrow">
                                    <Link to="services" class="theme-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img05} alt="Outstation-Taxi" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Outstation Taxi
                                </h3>
                                <p class="service-text">
                                    With Triveego outstation taxi service, you can go from Dehradun, Rishikesh
                                </p>
                                <div class="service-arrow">
                                    <Link to="services" class="theme-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item" >
                            <div class="service-img">
                                <img src={img03} alt="Airport-Transport" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Airport Transport
                                </h3>
                                <p class="service-text">
                                    Begin or conclude your trip stress-free with our punctual airport transfer service.
                                </p>
                                <div class="service-arrow">
                                    <Link to="services" class="theme-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img04} alt="Railway-Station" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Railway Station Service
                                </h3>
                                <p class="service-text">
                                    Find and compare cabs in Rishikesh, Dehradun and Haridwar Railway Station with Triveego Taxi
                                </p>
                                <div class="service-arrow">
                                    <Link to="services" class="theme-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img01} alt="Chardham-Taxi" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Chardham Taxi
                                </h3>
                                <p class="service-text">
                                    Triveego handle all operations from Dehradun to Chardham and also provide
                                </p>
                                <div class="service-arrow">
                                    <Link to="services" class="theme-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img06} alt="Monthly-Cab-Service" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Monthly Cab Service
                                </h3>
                                <p class="service-text">
                                    Tired of the hassle of daily commuters parking, and fluctuating fares?
                                </p>
                                <div class="service-arrow">
                                    <Link to="services" class="theme-btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesComponent;