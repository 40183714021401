// import { NavLink } from 'react-router-dom';
import swift from '../images/swift.png'
import ertiga from '../images/ertiga.png'
import fronx from '../images/fronx.png'
const TaxiComponent = () => {

    return (
        <div class="taxi-area bg py-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mx-auto">
                        <div class="site-heading text-center">
                            <span class="site-title-tagline">Our Taxi</span>
                            <h2 class="site-title">Let's Check Available Taxi</h2>
                        </div>
                    </div>
                </div>
                <div class="filter-controls">
                </div>
                <div class="row filter-box">
                    <div class="col-md-6 col-lg-4 filter-item cat1 cat2" >
                        <div class="taxi-item">
                            <div class="taxi-img">
                                <img src={swift} alt="swift" />
                            </div>
                            <div class="taxi-content">
                                <div class="taxi-head">
                                    <h4>Dzire 2023 Model</h4>
                                </div>
                                <div class="taxi-feature">
                                    <ul>
                                        <li>Passengers: <span>4</span></li>
                                        <li>Air Condition: <span>Yes</span></li>
                                        <li>GPS Navigation: <span>Yes</span></li>
                                    </ul>
                                </div>
                                {/* <NavLink to="/" className="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></NavLink> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 filter-item cat3 cat4" >
                        <div class="taxi-item">
                            <div class="taxi-img">
                                <img src={ertiga} alt="ertiga" />
                            </div>
                            <div class="taxi-content">
                                <div class="taxi-head">
                                    <h4>ERTIGA 2023 Model</h4>
                                </div>
                                <div class="taxi-feature">
                                    <ul>
                                        <li>Passengers: <span>6</span></li>
                                        <li>Air Condition: <span>Yes</span></li>
                                        <li>GPS Navigation: <span>Yes</span></li>
                                    </ul>
                                </div>
                                {/* <NavLink to="/" className="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></NavLink> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 filter-item cat1 cat4">
                        <div class="taxi-item">
                            <div class="taxi-img">
                                <img src={fronx} alt="fronx" />
                            </div>
                            <div class="taxi-content">
                                <div class="taxi-head">
                                    <h4>Fronx 2023 Model</h4>
                                </div>
                                <div class="taxi-feature">
                                    <ul>
                                        <li>Passengers: <span>4</span></li>
                                        <li>Air Condition: <span>Yes</span></li>
                                        <li>GPS Navigation: <span>Yes</span></li>
                                    </ul>
                                </div>
                                {/* <NavLink to="/" className="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TaxiComponent;