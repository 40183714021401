import img01 from '../../images/contact.jpg';
import React, { Fragment, useState } from "react";
import moment from "moment/moment";
import axios from 'axios';

const ContactComponent = () => {

    const api = axios.create({
        baseURL: "https://www.triveego.com/",
        headers: {
            "Content-Type": "application/json",
        }
    })

    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [nameError, setNameError] = useState('');
    const [contactError, setContactError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    const [successPopupShow, setSuccessPopupShow] = useState(false);

    const closeModalPopup = () => {
        setSuccessPopupShow(false);
    }

    const submitContact = async () => {
        const mobileNoRegex = RegExp(
            /^((\+){0,1}91(\s){0,1}(\-){0,1}(\s){0,1}){0,1}[6-9][0-9](\s){0,1}(\-){0,1}(\s){0,1}[1-9]{1}[0-9]{7}$/gm
        );

        const emailRegex = RegExp(
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );
        name.length < 1 ? setNameError("Please enter the name.") : setNameError("");
        contact.length < 1 ? setContactError("Please enter the contact no.") : mobileNoRegex.test(contact) ? setContactError("") : setContactError("Please enter valid mobile no.");
        email.length < 1 ? setEmailError("Please Fill Email") : emailRegex.test(email) ? setEmailError("") : setEmailError("Invalid Email Address");
        message.length < 1 ? setMessageError("Please enter the message.") : setMessageError("");

        if (name.length > 0 && contact.length > 0 && email.length > 0 && message.length > 0) {

            console.log(mobileNoRegex.test(contact));
            console.log(emailRegex.test(email));
            if (mobileNoRegex.test(contact) && emailRegex.test(email)) {
                let payload = {
                    name: name,
                    contact: contact,
                    email: email,
                    message: message,
                    currentDateTime: moment().format("YYYY-MM-DD HH:mm:ss")
                }
                try {
                    const { data } = await axios.post(`https://triveego.com/api/contact.php`, { 'data': payload },
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        });
                    if (data) {
                        //$('#bookingQueryModalPopup').modal('show');
                        setSuccessPopupShow(true);
                        setName('');
                        setContact('');
                        setEmail('');
                        setMessage('');
                        setNameError('');
                        setContactError('');
                        setEmailError('');
                        setMessageError('');
                    }
                } catch (err) {
                    console.error(err.message);
                }
            }
        }

    }

    return (
        <Fragment>
            <div className="contact-area py-120">
                <div className="container">
                    <div className="contact-content">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="contact-info">
                                    <div className="contact-info-icon">
                                        <i className="fal fa-map-location-dot" />
                                    </div>
                                    <div className="contact-info-content">
                                        <h5>Office Address</h5>
                                        <p>11km Stone, Chamba Mussoorie Road, Tehri, Kanatal, Uttarakhand </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-info">
                                    <div className="contact-info-icon">
                                        <i className="fal fa-phone-volume" />
                                    </div>
                                    <div className="contact-info-content">
                                        <h5>Call Us</h5>
                                        <p>+91 7906636662</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-info">
                                    <div className="contact-info-icon">
                                        <i className="fal fa-envelopes" />
                                    </div>
                                    <div className="contact-info-content">
                                        <h5>Email Us</h5>
                                        <p>info@triveego.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="contact-info">
                                    <div className="contact-info-icon">
                                        <i className="fal fa-alarm-clock" />
                                    </div>
                                    <div className="contact-info-content">
                                        <h5>Open Time</h5>
                                        <p>Mon - Sun (24H)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-wrapper">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <div className="contact-img">
                                    <img src={img01} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="contact-form">
                                    <div className="contact-form-header">
                                        <h2>Get in Touch with Triveego</h2>
                                        <p>We're Here to Assist You with Any Inquiries or Booking Requests                                    </p>
                                    </div>
                                    <form
                                        method="post"
                                        action="/taxica/assets/php/contact.php"
                                        id="contact-form"
                                    >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        value={name}
                                                        onChange={e => setName(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        placeholder="Your Name"
                                                        required=""
                                                    />
                                                    {nameError?.length > 0 && (
                                                        <span className="errorMessage">{nameError}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        value={contact}
                                                        onChange={e => setContact(e.target.value)}
                                                        type="number"
                                                        className="form-control"
                                                        name="contact"
                                                        placeholder="Your Contact Number"
                                                        required=""
                                                    />
                                                    {contactError?.length > 0 && (
                                                        <span className="errorMessage">{contactError}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Your Email"
                                                required=""
                                            />
                                            {emailError?.length > 0 && (
                                                <span className="errorMessage">{emailError}</span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <textarea
                                                value={message}
                                                onChange={e => setMessage(e.target.value)}
                                                name="message"
                                                cols={30}
                                                rows={5}
                                                className="form-control"
                                                placeholder="Write Your Message"

                                            />
                                            {messageError?.length > 0 && (
                                                <span className="errorMessage">{messageError}</span>
                                            )}
                                        </div>
                                        <div className="col-lg-2 align-self-end">
                                            <button onClick={submitContact} className="theme-btn" type="button">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(successPopupShow) ?
                <div id="bookingQueryModalPopup" className={"modal booking_modal_popup " + (successPopupShow ? 'show' : '')} style={{ display: "block" }} data-bs-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={closeModalPopup} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body"><div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} viewBox="0 0 24 24" fill="none" stroke="#a7e92f" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" /><polyline points="22 4 12 14.01 9 11.01" /></svg>
                                <h3>Sent Successfully !</h3>
                                <p>Thank you for contacting us, we will call you shortly.</p></div></div>

                        </div>
                    </div>
                </div>

                :
                ''

            }
        </Fragment>

    );
}

export default ContactComponent;