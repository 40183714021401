import img01 from '../../images/city-tour.jpeg';
import img02 from '../../images/Chardham.jpg';
import img03 from '../../images/airport.jpeg';
import img04 from '../../images/04.jpg';
import img05 from '../../images/05.jpg';
import img06 from '../../images/06.jpg';
const ServicesComponent = () => {
    return (
        <div class="service-area py-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mx-auto">
                        <div class="site-heading text-center">
                            <span class="site-title-tagline">Services</span>
                            <h2 class="site-title">Our Best Services For You</h2>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img01} alt="City-Taxi" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    City Taxi
                                </h3>
                                <p class="service-text">
                                    Explore the beauty and attractions of your city with triveego taxi service. Our experienced drivers will take you to all the must-see locations while helping you on the route.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img05} alt="Outstation-Taxi" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Outstation Cab
                                </h3>
                                <p class="service-text">
                                    Triveego outstation taxi service can take you anywhere from Dehradun, Rishikesh, Kanatal, and Dhanaulti to Mussoorie, Shimla, Haridwar, Roorkee, Delhi, Manali, Chandigarh, etc. Whether it is a vacation trip, romantic honeymoon or a journey with a spiritual sense, you are in Dehradun and Uttarakhand Triveego Taxi Service is your reliable partner for all your travel needs From solo adventurers to quality-time-seeking families, from intimate groups to large gatherings , Triveego Taxi Service offers a variety of options to cater to every passenger in Uttarakhand
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item" >
                            <div class="service-img">
                                <img src={img03} alt="Airport-Transport" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Airport Transport
                                </h3>
                                <p class="service-text">
                                    Triveego Taxi Service Experience easy and comfortable transportation with extremely clean taxis, specially designed for traveling to and from Jolly Grant Airport. As the leading taxi service to Dehradun, Rishikesh, and Haridwar, Triveego ensures the highest quality of taxi service at an affordable rate. Book your taxi now for a hassle-free travel experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img04} alt="Railway-Station" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Railway Station Service
                                </h3>
                                <p class="service-text">
                                    Find and compare taxis at Dehradun and Rishikesh railway stations with the Triveego taxi. Find the best prices quickly and book online. Start your journey now!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img02} alt="Chardham-Taxi" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Chardham Taxi
                                </h3>
                                <p class="service-text">
                                    Triveego handles all operations from Dehradun to Chardham and also provides Chardham taxi services to our customers from Dehradun, Rishikesh, Haridwar and all over Uttarakhand. All types of cars are available for our taxi services in Dehradun, Rishikesh, and Haridwar.  For all of your travel needs, we have the Innova Crysta, Swift DZire, Ertiga, Fronx, and Tempo Traveller available.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="service-item">
                            <div class="service-img">
                                <img src={img06} alt="Monthly-Cab" />
                            </div>
                            <div class="service-content">
                                <h3 class="service-title">
                                    Monthly Cab Service
                                </h3>
                                <p class="service-text">
                                    Tired of the hassle of daily commuter parking and fluctuating fares? All of your transportation needs can be easily and affordably met by your monthly taxi service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesComponent;