import { NavLink } from 'react-router-dom';

const CtaComponent = () => {

    return (
        <div class="cta-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 text-center text-lg-start">
                        <div class="cta-text cta-divider">
                            <h1>Book Your Cab It's Simple <br />And Affordable</h1>
                        </div>
                    </div>
                    <div class="col-lg-5 text-center text-lg-end">
                        <div class="mb-20">
                            <a href="tel:+917906636662" class="cta-number"><i class="far fa-headset"></i>+91 7906636662</a>
                        </div>
                        <div class="cta-btn">
                            <NavLink to="/" className="theme-btn">Book Your Cab</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CtaComponent;