
import './App.css';
import React, { Fragment } from "react";

import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";

//import Dashboard from "./components/Dashboard";
import Index from "./pages/index";
import Header from "./components/header";
import Footer from "./components/footer";
import Admin from "./components/admin";
import ServicePageComponent from './pages/servicesPage';
import AboutPageComponent from './pages/aboutPage';
import ContactPageComponent from './pages/contactPage';
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="about" element={<AboutPageComponent />} />
          <Route path="services" element={<ServicePageComponent />} />
          <Route path="contact" element={<ContactPageComponent />} />
          <Route path="/admin" element={<Admin />} />
          {/*<Route path="/login" element={!checkIsAuthenticated ? (<Login />) : ( <Navigate replace to="/dashboard" /> )} />
              <Route path="/register" element={!checkIsAuthenticated ? (<Register />) : ( <Navigate replace to="/dashboard" /> )} />
              <Route path="/dashboard" element={checkIsAuthenticated ? (<Dashboard />) : ( <Navigate replace to="/login" /> )} />*/}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
