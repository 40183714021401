import AboutComponent from "../components/about/about";
import BreadcrumbComponent from "../components/breadcrumb";

const AboutPageComponent = () => {
    return (
        <main className="main">
            <BreadcrumbComponent name="About" title="About" />
            <AboutComponent />
        </main>
    );
}

export default AboutPageComponent;