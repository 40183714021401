import BreadcrumbComponent from "../components/breadcrumb";
import ContactComponent from "../components/contact/contact";

const ContactPageComponent = () => {
    return (
        <main className="main">
            <BreadcrumbComponent name="Contact" title="Contact" />
            <ContactComponent />
            {/* <MapComponent /> */}
        </main>
    );
}

export default ContactPageComponent;